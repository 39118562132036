import { useRouter } from "next/router";
import React, { createContext, useState, useEffect } from "react";

interface AppContextProps {
  tagQuery: any;
  setTagQuery: (q: any) => void;
}

export const AppContext = createContext<AppContextProps>({
  tagQuery: null,
  setTagQuery: () => {},
});

export const AppProvider = ({ children }: any) => {
  const router = useRouter();
  const [tagQuery, setTagQuery] = useState<any>(null);
  useEffect(() => {
    if (!router.pathname.includes("/blogs")) {
      setTagQuery(null);
    }
  }, [router]);
  return (
    <AppContext.Provider value={{ tagQuery, setTagQuery }}>
      {children}
    </AppContext.Provider>
  );
};
