import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { firebaseClientConfig } from "config/firebase";
import {
  logEvent,
  EventNameString,
  getAnalytics,
  EventParams,
  AnalyticsCallOptions,
} from "firebase/analytics";
import { FirebasePerformance, getPerformance } from "firebase/performance";

// Utils
// import { sentryErrorLog } from "./sentryLog";
import { getReferenceParams } from "app-head";

let _fbApp: FirebaseApp | undefined = undefined;

let _perf: FirebasePerformance | undefined = undefined;

if (typeof window !== "undefined" && getApps().length <= 0) {
  _fbApp = initializeApp(firebaseClientConfig, {
    automaticDataCollectionEnabled: true,
  });
  _perf = getPerformance(_fbApp);
}

export const fbApp = _fbApp;

export const perf = _perf;

export const trackEvent = (
  eventName: EventNameString | string,
  eventParams: EventParams = {},
  options?: AnalyticsCallOptions
) => {
  try {
    const refParams = getReferenceParams();
    (window as any).rudderanalytics.track(eventName, {
      ...eventParams,
      dapp: "landing",
      ...refParams,
    });
  } catch (e) {
    // TODO report sentry
    // sentryErrorLog(e);
  }
  try {
    const analytics = getAnalytics(_fbApp);
    logEvent(analytics, eventName, { ...eventParams }, options);
    (window as any).dataLayer?.push({
      event: eventName,
      event_triggerd_by: "GTM",
      ...eventParams,
    });
  } catch (e) {
    // TODO report sentry
    // sentryErrorLog(e);
  }
};
